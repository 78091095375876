import React from "react";
import "../../styles/LandingPage.css";
import CodeSimpleLogo from "../../assets/CodeSimpleLogo.svg";
import TryItNowImage from "../../assets/Group 54.png";
import CLIBulb from "../../assets/CLIBulb.svg";
import AIIcon from "../../assets/AIIcon.svg";
import TDDIcon from "../../assets/TDDIcon.svg";
import CofounderImage from "../../assets/CofounderImage.svg";
// import BlogImage from "../../assets/BlogImage.svg";
import Login from "../login/Login";

const LandingPageMain: React.FC = () => {
  return (
    <div className="Landing_Page_Main" data-testid="landing-page-main">
      <HeaderContainer />
      <CardContainer />
      <DeveloperSection />
      {/* <SubscribeSection /> */}
      <FooterSection />
    </div>
  );
};

export default LandingPageMain;

export const HeaderContainer = () => {
  const [show, setShow] = React.useState(false);

  const handleLoginClick = (data : boolean) => {
    setShow(data);
  };

  const handleClick = () =>{
    setShow(true);

  }

  return (
    <div className="Header_Container_Main">
      <div className="Head_Navigator">
        <div className="Head_Navigator_Box1">
          <img src={CodeSimpleLogo} alt="" />
          <div className="Header_Navigator_List">
            {/* <p>Link Item</p>
            <p>Link Item</p> */}
          </div>
        </div>
        <div className="Head_Navigator_Box2">
          <div className="Head_Navigator_Button">
            <button
              className="Head_Navigator_LoginBtn"
              onClick={handleClick}
            >
              Login
            </button>
            {/* <button className="Head_Navigator_RegisterBtn">Register</button> */}
          </div>
        </div>
      </div>
      <div className="Content_Container">
        <div className="Content_Container_Box1">
          <h1>
            <span>Accelerate</span>
            <br />
            your software
            <br />
            development
          </h1>
          <div className="Content_Container_Box1_Para">
            <p>
              CodeSimple revolutionizes development through a series of prompts
              that deliver high-quality, thoroughly tested code.
            </p>
          </div>
        </div>
        <div className="Content_Container_Box2">
          <div className="Content_Container_Box2_Img">
            <img src={TryItNowImage} alt="" />
          </div>
        </div>
        {show && <Login handleLoginClick={handleLoginClick} />}
      </div>
    </div>
  );
};

export const CardContainer = () => {
  return (
    <div className="Card_Container_Main">
      <div className="Card_Container_Main_Box">
        <div className="Card_Container_Box1">
          <div className="Card_Container_Box1_Image">
            <div className="RadialBlurBackground"></div>
            <img src={CLIBulb} alt="" />
          </div>

          <h4>Intelligent Command Line</h4>
          <p>
            Work where you want to work: Whether on the web or on the
            comandline, generate, test, commit and deploy code from the command
            line.
          </p>
        </div>
        <div className="Card_Container_Box2">
          <div className="Card_Container_Box1_Image">
            <div className="RadialBlurBackground"></div>
            <img src={AIIcon} alt="" />
          </div>
          <h4>AI-generated Code</h4>
          <p>
            Prompt-driven: Collaborate with our agent, ideate together, and let
            the AI do the heavy lifting. Apply industry best pracises with a
            single command.
          </p>
        </div>
        <div className="Card_Container_Box3">
          <div className="Card_Container_Box1_Image">
            <div className="RadialBlurBackground"></div>
            <img src={TDDIcon} alt="" />
          </div>{" "}
          <h4>Unit Test-Driven Dev</h4>
          <p>
            What's built is driven from tests, so that you know that it works,
            that it's reliable, and that you can change it without breaking it.
          </p>
        </div>
      </div>
    </div>
  );
};

export const DeveloperSection = () => {
  return (
    <div className="Developer_Section_Main">
      <div className="Developer_Section_Box1"></div>
      <div className="Developer_Section_Box2">
        <img src={CofounderImage} alt="" />
        <div className="Developer_Section_Cofounder_Para">
          <p>
            A seasoned developer and entrepreneur, Ian has been building
            innovative software since before the dawn of the World Wide Web. He
            has worked with companies large and small, from startups to Fortune
            500s, and has been a founder of several successful startups. The
            learning from leading Pivotal Labs brings singular insights into
            test driven validation.
          </p>
          <span>- Ian McFarland, Founder CodeSimple</span>
        </div>
      </div>

      {/* <div className="Developer_Section_Box3">
        <div className="Developer_Section_Blog_Box1">
          <img src={BlogImage} alt="" />
          <div className="Developer_Section_Blog_Box1_Overlay">
            <div className="Developer_Section_Blog_Box1_Overlay_Text">
              <span>Dec 1, 2023</span>
              <h2>Excepteur sint occaecat cupidatat non proident</h2>
              <p>
                Excepteur sint occaecat cupidatat non proident, sunt in culpa
                qui officia deserunt mollit anim id est laborum
              </p>
            </div>
            <div className="Developer_Section_Blog_Box1_Overlay_Button">
              <button className="Developer_Section_Blog_Box1_Overlay_Btn">
                Read Article
              </button>
            </div>
          </div>
        </div>

        <div className="Developer_Section_Blog_Box2">
          <img src={BlogImage} alt="" />
          <div className="Developer_Section_Blog_Box1_Overlay">
            <div className="Developer_Section_Blog_Box1_Overlay_Text">
              <span>Dec 1, 2023</span>
              <h2>Excepteur sint occaecat cupidatat non proident</h2>
              <p>
                Excepteur sint occaecat cupidatat non proident, sunt in culpa
                qui officia deserunt mollit anim id est laborum
              </p>
            </div>
            <div className="Developer_Section_Blog_Box2_Overlay_Button">
              <button className="Developer_Section_Blog_Box1_Overlay_Btn">
                Read Article
              </button>
            </div>
          </div>
        </div>
      </div> */}

      <div className="Developer_Section_Box1"></div>
    </div>
  );
};

// const SubscribeSection = () => {
//   return (
//     <div className="Subscription_Section_Container">
//       <div className="Subscription_Section_Box1">
//         <h1>
//           Accelerate your dev flow <span>now</span>
//         </h1>
//       </div>
//       <div className="Subscription_Section_Box2">
//         <div className="Subscription_Section_Box2_Free">
//           <div className="Subscription_Section_Box2_Free_Content">
//             <h4 className="Subscription_Section_Box2_Free_Title">Free</h4>
//             <h1>
//               <sup>$</sup>0
//             </h1>
//             <button className="Subscription_Section_Box2_Free_Button">
//               <b>GET STARTED</b>
//             </button>
//             <ul>
//               <li>Unlimited users</li>
//               <li>Unlimited objects</li>
//               <li>2 environments</li>
//               <li>3 days of activity history</li>
//             </ul>
//           </div>
//         </div>
//         <div className="Subscription_Section_Box2_Priceline">
//           <div className="Subscription_Section_Box2_Free_Content">
//             <h4 className="Subscription_Section_Box2_Priceline_Title">
//               Priceline
//             </h4>
//             <h1>
//               <sup>$</sup>10
//             </h1>
//             <button className="Subscription_Section_Box2_Priceline_Button">
//               <b>TRY FOR FREE</b>
//             </button>
//             <ul>
//               <li>Unlimited users</li>
//               <li>Unlimited objects</li>
//               <li>2 environments</li>
//               <li>3 days of activity history</li>
//             </ul>
//           </div>
//         </div>
//         <div className="Subscription_Section_Box2_Enterprise">
//           <div className="Subscription_Section_Box2_Free_Content">
//             <h4 className="Subscription_Section_Box2_Enterprise_Title">
//               Enterprise
//             </h4>
//             <h1>
//               <sup>$</sup>40
//             </h1>
//             <button className="Subscription_Section_Box2_Enterprise_Button">
//               <b>TRY FOR FREE</b>
//             </button>
//             <ul>
//               <li>Unlimited users</li>
//               <li>Unlimited objects</li>
//               <li>2 environments</li>
//               <li>3 days of activity history</li>
//             </ul>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

export const FooterSection = () => {
  return (
    <div className="Footer_Section_Container">
      <div className="Footer_Section_Container_Box1">
        <img src={CodeSimpleLogo} alt=""></img>
        <p>Copyright @ 2023 CodeSimple</p>
      </div>
    </div>
  );
};
