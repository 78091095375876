// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .secondaryButton{
    background: #414765;
    color: white;
    border-radius: 3px;
} */

/* CustomButton.css */

.custom-button.secondaryButton {
    background: #414765;
    color: white;
    border-radius: 3px;
    transition: background-color 0.3s ease; /* Add a smooth transition for the hover effect */
  }
  
  .custom-button.secondaryButton:hover {
    background-color: #1e1f2b; /* Change the background color on hover */
  }
  
  .custom-button.primaryButton{
    background:#694DC2;
    color: white;
    border-radius: 3px;
    transition: background-color 0.3s ease;
  }

  .custom-button.primaryButton:hover {
    background-color: #1e1f2b; /* Change the background color on hover */
  }`, "",{"version":3,"sources":["webpack://./src/styles/Button.css"],"names":[],"mappings":"AAAA;;;;GAIG;;AAEH,qBAAqB;;AAErB;IACI,mBAAmB;IACnB,YAAY;IACZ,kBAAkB;IAClB,sCAAsC,EAAE,iDAAiD;EAC3F;;EAEA;IACE,yBAAyB,EAAE,yCAAyC;EACtE;;EAEA;IACE,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,sCAAsC;EACxC;;EAEA;IACE,yBAAyB,EAAE,yCAAyC;EACtE","sourcesContent":["/* .secondaryButton{\n    background: #414765;\n    color: white;\n    border-radius: 3px;\n} */\n\n/* CustomButton.css */\n\n.custom-button.secondaryButton {\n    background: #414765;\n    color: white;\n    border-radius: 3px;\n    transition: background-color 0.3s ease; /* Add a smooth transition for the hover effect */\n  }\n  \n  .custom-button.secondaryButton:hover {\n    background-color: #1e1f2b; /* Change the background color on hover */\n  }\n  \n  .custom-button.primaryButton{\n    background:#694DC2;\n    color: white;\n    border-radius: 3px;\n    transition: background-color 0.3s ease;\n  }\n\n  .custom-button.primaryButton:hover {\n    background-color: #1e1f2b; /* Change the background color on hover */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
