import { Grid, Paper, Typography, Stack, ListItem, Box } from "@mui/material";
import React from "react";
import Sidebar from "./Sidebar";
import SideFolderIcon from "../assets/SideFolderIcon.svg";
import codeBranch from "../assets/codeBranch.svg";
import fi from "../assets/fi.svg";
import github from "../assets/github.png";
import AICommands from "./middleComponent/aiCommands/AICommands";

const Main = () => {
  return (
    <div data-testid="main-component" style={{ display: "flex" }}>
      <Stack
        direction="column"
        style={{
          width: "45px",
          height: "91vh",
          background: "#363E56",
        }}
      >
        <Box sx={{ marginTop: "25px" }}>
          <ListItem sx={{ justifyContent: "center" }}>
            <img src={SideFolderIcon} alt="folder" />
          </ListItem>
          <ListItem sx={{ justifyContent: "center" }}>
            <img src={codeBranch} alt="folder" />
          </ListItem>
          <ListItem sx={{ justifyContent: "center" }}>
            <img src={fi} alt="folder" />
          </ListItem>
          <ListItem sx={{ justifyContent: "center" }}>
            <img src={github} alt="folder" />
          </ListItem>
        </Box>
      </Stack>

      <Grid container spacing={0}>
        <Grid item xs={12} md={3} lg={3} sm={3}>
          <Sidebar />
        </Grid>
        <Grid item xs={12} md={6} lg={6} sm={6}>
            <AICommands />
        </Grid>

        <Grid item xs={12} md={3} lg={3} sm={3}>
          <Paper>
            {/* <Typography variant="h6">Item 3 (Width: 3)</Typography> */}
            <Box sx={{height:"91vh"  , background:"linear-gradient(to bottom, rgba(19, 23, 38, 1), rgba(54, 62, 86, 1))"}}>
            <Typography sx={{color:"#A0A8C4", textAlign:"center"}}  variant="h5">Chat Box Comming Soon</Typography>
            </Box>  
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default Main;
