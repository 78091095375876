// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.folder-container{
    margin: 0px;
}

.folder-box{
    display :flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
}

.folder-box>img{
    margin:2% ;
}

.files-container{
    margin : 2%;
    display: flex;
    flex-direction: row;
}

.files{
    display: flex;
    color: #A0A8C4;
    border-radius: 10px;
    justify-content: flex-start;
}

.files>img{
    margin-left: 4%;
}

`, "",{"version":3,"sources":["webpack://./src/styles/FileExplorer.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,aAAa;IACb,2BAA2B;IAC3B,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,WAAW;IACX,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,cAAc;IACd,mBAAmB;IACnB,2BAA2B;AAC/B;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".folder-container{\n    margin: 0px;\n}\n\n.folder-box{\n    display :flex;\n    justify-content: flex-start;\n    align-items: center;\n    border-radius: 10px;\n}\n\n.folder-box>img{\n    margin:2% ;\n}\n\n.files-container{\n    margin : 2%;\n    display: flex;\n    flex-direction: row;\n}\n\n.files{\n    display: flex;\n    color: #A0A8C4;\n    border-radius: 10px;\n    justify-content: flex-start;\n}\n\n.files>img{\n    margin-left: 4%;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
