import React from "react";
import {
  TextField,
  Dialog,
  Paper,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Box,
  CircularProgress,
  DialogActions,
  Slide,
} from "@mui/material";
import "../styles/Login.css";
import CustomizedSnackbars from "./ErrorAlert";
import { TransitionProps } from "@mui/material/transitions";
import SecondaryButton from "./SecondaryButton";
import PrimaryButton from "./PrimaryButton";

interface ProjectConfigItem {
  description: string;
  label: string;
  required: boolean;
  type: string;
  display?: boolean;
  from_context?: boolean;
}

interface ProjectConfig {
  [key: string]: ProjectConfigItem;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export interface DialogGenericProps {
  displayName: string;
  handleClose: () => void;
  handleSubmit: (e: any) => void;
  handleUpdate: (e: any) => void;
  loading: boolean;
  errorPost: boolean;
  open: boolean;
  fetchVerb: ProjectConfig;
  value?: string;
  passToDialog?: (open: boolean) => void;
  validationErrors: { [key: string]: string };
  projectName?: string | null;
}

function DialogGeneric(props: DialogGenericProps) {
  const open = Boolean(props.open);

  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={props.handleClose}
        aria-describedby="alert-dialog-slide-description"
        className="dialogBox"
        data-testid="dialog"
        sx={{
          "& .css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
            backgroundColor: "transparent",
          },
        }}
      >
        <Paper
          style={{
            background:
              "linear-gradient(to bottom, rgba(27, 31, 49, 1), rgba(34, 38, 60, 1))",
          }}
        >
          <DialogTitle
            sx={{ color: "white", fontSize: "18px", fontWeight: 700 }}
          >
            {props.displayName}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description"></DialogContentText>
            <div>
              <form onSubmit={props.handleSubmit}>
                {props.loading ? (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  Object.keys(props.fetchVerb).map((key: string) => {
                    const property: ProjectConfigItem = props.fetchVerb[key];
                    if (property?.display === false && key === "project_id") {
                      return <input type="hidden" name={key} key={key} />;
                    } else
                      return (
                        <div key={key} style={{ margin: "2%" }}>
                          <label
                            htmlFor={key}
                            style={{
                              color: "#A0A8C4",
                              fontSize: "14px",
                              fontWeight: 600,
                            }}
                            className={`${property.required ? "RequiredClass" :""}`}
                          >
                            {property.label}&nbsp;{property.required && "*"}
                            <TextField
                              margin="dense"
                              id={key}
                              style={{
                                display:
                                  property?.display === false
                                    ? "none"
                                    : "inline-block",
                              }}
                              placeholder={`Type ${property.label} Here`}
                              type={key === "password" ? "password" : "text"}
                              name={key}
                              // required={property.required}
                              error={!!props.validationErrors[key]}
                              helperText={props.validationErrors[key]}
                              sx={{
                                "& input::placeholder": {
                                  color: "#A0A8C4",
                                },
                              }}
                              autoComplete="off"
                              InputProps={{
                                style: { color: "#A0A8C4" }, // Set your desired text color
                              }}
                              fullWidth
                              onChange={props.handleUpdate}
                            />
                          </label>
                        </div>
                      );
                  })
                )}
                <DialogActions>
                  <SecondaryButton onClick={props.handleClose}>
                    Cancel
                  </SecondaryButton>
                  <PrimaryButton type="submit">Submit</PrimaryButton>
                </DialogActions>
              </form>
            </div>
          </DialogContent>
        </Paper>
      </Dialog>
      {props.errorPost && <CustomizedSnackbars />}
    </React.Fragment>
  );
}

export default DialogGeneric;
