import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import {
  MenuItem,
  Avatar,
  Button,
  Divider,
  Menu,
  Tooltip,
  Badge,
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Group from "../assets/Group.png";
import { BASE_URL } from "../config/config";
import { useApiKey } from "../generic components/ApiKeyContext";
import { useProject } from "../generic components/ProjectContext";
import "../styles/Header.css";
import { styled } from "@mui/material";

const CustomMenu = styled(Menu)({
  "& .MuiList-root": {
    background: "linear-gradient(to left, #1F233B, #181D2B)",
  },

  "& .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper ": {
    overflowY: "scroll",
    background: "linear-gradient(to left, #1F233B, #181D2B)",
    "&::-webkit-scrollbar": {
      width: "10px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#2d3857",
      borderRadius: "5px",
    },
    "&:hover::-webkit-scrollbar-thumb": {
      background: "#2d3857",
    },
  },
});

const CustomMenuItem = styled(MenuItem)({
  color: "#A0A8C4",
  fontSize: "14px",
  fontFamily: "Montserrat",
});

export default function SearchAppBar() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorPl, setAnchorPl] = React.useState<null | HTMLElement>(null);
  const [project, setProject] = React.useState([] as any);
  const [projectNameOnHeader, setProjectNameOnHeader] = React.useState(
    "Project Name" as string | null
  );
  const open = Boolean(anchorEl);
  const openPl = Boolean(anchorPl);
  // api_key is coming from genericComponent/ApiKeyContext
  const { api_key } = useApiKey();
  // project name is coming from ProjectContext
  const { setProjectId, setProjectName, createProject } = useProject();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setAnchorPl(null);
  };
  const handleSelect = () => {
    setAnchorEl(null);
  };
  const handleClickProject = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorPl(event.currentTarget);
  };
  const handleSelectProject = (
    event: React.MouseEvent,
    id: string,
    short_name: string,
    human_name: string,
    dir_name: string
  ) => {
    setAnchorPl(null);
    setProjectId(id);
    if (human_name !== null) {
      if (human_name) {
        setProjectName!(human_name);
      }
    } else {
      setProjectName!(dir_name);
    }
    setProjectNameOnHeader(event.currentTarget.textContent);
  };

  React.useEffect(() => {
    const fetchProject = async () => {
      try {
        const headers: HeadersInit = {
          "Content-Type": "application/json",
          "X-API-Key": api_key,
        };
        const response = await fetch(`${BASE_URL}/project`, {
          method: "GET",
          headers,
        });

        if (!response.ok) {
          throw new Error(
            `Error Fetching the project (HTTP ${response.status})`
          );
        }

        const data = await response.json();
        setProject(data.projects);
      } catch (err) {
        console.log(`Error Fetching the project ${err}`);
      }
    };

    fetchProject();
  }, [api_key, createProject]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        sx={{ background: "#363E56", boxShadow: "none" }}
      >
        <Toolbar>
          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <img
              src={Group}
              alt="Group"
              style={{ height: "14px", width: "21px" }}
            />
          </Button>
          <CustomMenu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <CustomMenuItem onClick={handleSelect}>New File</CustomMenuItem>
            <CustomMenuItem onClick={handleSelect}>Open Recent</CustomMenuItem>
            <CustomMenuItem onClick={handleSelect}>
              Save with Encoding
            </CustomMenuItem>
            <CustomMenuItem onClick={handleSelect}>Save As</CustomMenuItem>
            <CustomMenuItem onClick={handleSelect}>Save All</CustomMenuItem>
            <Divider />
            <CustomMenuItem onClick={handleSelect}>
              Close Project
            </CustomMenuItem>
          </CustomMenu>

          <Box sx={{ width: "100vw" }}>
            <Button
              id="ProjectButton"
              aria-controls={open ? "Project-Menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClickProject}
            >
              {projectNameOnHeader}
            </Button>
          </Box>
          <CustomMenu
            id="Project-Menu"
            anchorEl={anchorPl}
            open={openPl}
            sx={{ color: "primary.main" }}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "ProjectButton",
            }}
            className="Header_Custom_Menu"
          >
            {project &&
              project.map((ob: any) => {
                return (
                  <CustomMenuItem
                    onClick={(e: React.MouseEvent) =>
                      handleSelectProject(
                        e,
                        ob.id,
                        ob.short_name,
                        ob.human_readable_name,
                        ob.dir_name
                      )
                    }
                    key={ob.id}
                  >
                    {ob.human_readable_name
                      ? ob.human_readable_name
                      : ob.dir_name}
                  </CustomMenuItem>
                );
              })}
          </CustomMenu>

          <MenuItem>
            <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
            >
              <Badge badgeContent={0} color="error">
                <NotificationsIcon sx={{ color: "#5D647C" }} />
              </Badge>
            </IconButton>
          </MenuItem>

          <Tooltip title="Open Profile">
            <IconButton sx={{ p: 0 }}>
              <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
