// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AICommands_Main_Box1_Content>p{
    color : #A0A8C4; 
    padding-left: 20px;
    font-size: 14px;
}

.MonacoEditor_Component{
    position: absolute;
}

`, "",{"version":3,"sources":["webpack://./src/styles/AICommand.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".AICommands_Main_Box1_Content>p{\n    color : #A0A8C4; \n    padding-left: 20px;\n    font-size: 14px;\n}\n\n.MonacoEditor_Component{\n    position: absolute;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
