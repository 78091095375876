import * as React from "react";
import { BASE_URL } from "../config/config";
import { useApiKey } from "../generic components/ApiKeyContext";
import DialogGeneric from "../generic components/DialogGeneric";
import { useProject } from "../generic components/ProjectContext";

interface ProjectConfigItem {
  description: string;
  label: string;
  required: boolean;
  type: string;
  display?: boolean;
}

interface ProjectConfig {
  [key: string]: ProjectConfigItem;
}

interface DialogBoxProps {
  boxOpen: boolean;
  value: string;
  displayName: string;
  callfromDialog: (boxOpen: boolean) => void;
}

export default function AlertDialogSlide(props: DialogBoxProps) {
  const [open, setOpen] = React.useState(true);
  const [fetchVerb, setFetchVerb] = React.useState<ProjectConfig>({});
  const [loading, setLoading] = React.useState(true);
  const [formData, setFormData] = React.useState({});
  const [errorPost, setErrorPost] = React.useState(false);
  const [validationErrors, setValidationErrors] = React.useState<{
    [key: string]: string;
  }>({});
  const { projectId, createProject, setCreateProject } = useProject();

  const { api_key } = useApiKey();

  const handleClose = () => {
    setOpen(false);
    props.callfromDialog(false);
  };

  React.useEffect(() => {
    const fetchVerbByName = async () => {
      try {
        const headers: HeadersInit = {
          "Content-Type": "application/json",
          "X-API-Key": api_key,
        };
        const response = await fetch(`${BASE_URL}/verb/${props.value}`, {
          method: "GET",
          headers,
        });
        const data: ProjectConfig = await response.json();
        console.log("fetch verb by value in dialog", data);
        setFetchVerb(data);
        setLoading(false);
      } catch (error) {
        console.log(`Error Fetching the verb by name ${error}`);
      }
    };
    fetchVerbByName();
  }, [props.value, api_key]);

  const handleUpdate = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setValidationErrors({ ...validationErrors, [e.target.name]: "" }); // Clear error message on input change
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    // Validate required fields
    const errors: { [key: string]: string } = {};
    Object.keys(fetchVerb).forEach((key: string) => {
      const property: ProjectConfigItem = fetchVerb[key];

      if (property.required && !formData[key as keyof typeof formData]) {
        errors[key] = `${property.label} is required.`;
      }
    });

    // Update state with validation errors
    setValidationErrors(errors);

    // If there are validation errors, stop submission
    if (Object.keys(errors).length > 0) {
      return;
    }

    console.log("form data", formData);


    try {
      const headers: HeadersInit = {
        "Content-Type": "application/json",
        "X-API-Key": api_key,
      };
      const response = await fetch(`${BASE_URL}/verb/${props.value}`, {
        method: "POST",
        headers,
        body: JSON.stringify(formData),
      });
      // Handle the response as needed
      e.target.reset();
      if (!response.ok) {
        throw new Error(`Error in form submit ${response}`);
      }
      if(props.value==="new_project"){
        setCreateProject!(!createProject);
      }
      setOpen(false);
      props.callfromDialog(false); // Close the dialog
      console.log("successfull", response);
    } catch (error) {
      console.log(`Error in Form Submit of ${props.value} `, error);
      setErrorPost(true);
    }
    setTimeout(() => {
      setErrorPost(false);
    }, 5000);
  };

  return (
    <>
      <DialogGeneric
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        handleUpdate={handleUpdate}
        loading={loading}
        errorPost={errorPost}
        open={open}
        displayName={props.displayName}
        fetchVerb={fetchVerb}
        value={props.value}
        passToDialog={props.callfromDialog}
        validationErrors={validationErrors}
        projectName={projectId}
      />
    </>
  );
}
