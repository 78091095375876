import React from "react";
import { Box, Grid } from "@mui/material";
import FileExplorer from "./FileExplorer";
import { useProject } from "../generic components/ProjectContext";
import { useApiKey } from "../generic components/ApiKeyContext";
import { BASE_URL } from "../config/config";
import SearchIcon from "../assets/searchIcon.svg";
import "../styles/Sidebar.css";

const Sidebar = () => {
  const [projectData, setProjectData] = React.useState<any>();
  const [selectedPath, setSelectedPath] = React.useState<string>("root");

  function onSelect(path: string) {
    setSelectedPath(path);
  }

  const { projectId } = useProject();
  const { api_key } = useApiKey();

  React.useEffect(() => {
    const fetchProjectById = async () => {
      try {
        const response = await fetch(`${BASE_URL}/project/${projectId}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "X-API-Key": api_key,
          },
        });
        if (!response.ok) {
          console.log("Check cors error");
          throw new Error(
            `Error Fetching the project (HTTP ${response.status})`
          );
        }
        const data = await response.json();
        setProjectData(data);
        console.log("project data", data);
      } catch (error) {
        console.log(`Error Fetching the project by ID , ${error}`);
      }
    };
    if (projectId !== null) {
      console.log("condition check", projectId);
      fetchProjectById();
    }
  }, [projectId, api_key]);

  return (
    <>
      <Grid container spacing={0}>
        <Grid
          item
          xs={12}
          md={12}
          sm={12}
          lg={12}
          sx={{
            background: "#363E56",
          }}
        >
          <Box className="Sidebar_Main_Container">
            <Box sx={{ margin: "15px", alignItems: "center" , overflowY:"hidden" }}>
      
              <div className="Explorer_Input">
                <input
                  type="text"
                  className="Input_Box"
                  placeholder="Type here to filter list"
                />
                <img src={SearchIcon} alt="Search button" />
              </div>
              <div className="Sidebar_Main_FileExplorer">
                <FileExplorer
                  folderData={projectData && Object.values(projectData['/'])}
                  rootName="."
                  depth={1}
                  onSelect={onSelect}
                  selectedPath={selectedPath}
                  setSelectedPath={setSelectedPath}
                />
              </div>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Sidebar;
