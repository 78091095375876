import React, { useRef, useEffect } from "react";
import * as monaco from "monaco-editor";
import "../../../styles/MonacoEditor.css";
import { useProject } from "../../../generic components/ProjectContext";

const CodeEditor: React.FC = () => {
  const editorRef = useRef<HTMLDivElement | null>(null);
  const editorInstance = useRef<monaco.editor.IStandaloneCodeEditor | null>(
    null
  );

  const {showEditorData} = useProject(); 

  useEffect(() => {
    const CreateEditor = () => {
     
      if (editorRef.current) {
        const editor = monaco.editor.create(editorRef.current, {
          value: "// Write Code",
          language: "javascript",
          theme: "vs-dark",
          automaticLayout: true,
        });

        editorInstance.current = editor;

        

        window.addEventListener("resize", () => {
          // Trigger Monaco Editor's automatic layout adjustment on window resize
          editor.layout();
        });
        return () =>
          window.removeEventListener("resize", () => editor.layout());
      }
    };
    CreateEditor();
    return () => {
      if (editorInstance.current) {
        editorInstance.current.dispose();
      }
    };
  }, []);


  useEffect(() =>{
    editorInstance.current?.setValue(showEditorData || "// Write Code");
  }, [showEditorData])

  return (
    <div
      className="Monaco_Editor_Outer_Wrapper"
      data-testid="MonacoEditor-comp"
    >
      <div ref={editorRef} className="Monaco_Editor_Main_Container1" />
    </div>
  );
};
export default CodeEditor;
