import React, { createContext, useContext, useState, ReactNode } from 'react';

// Define the type for the API key context
interface ApiKeyContextType {
  apiKey: string;
  setApiKey: (key: string) => void;
  api_key: string;
}

// Create a context for the API key
const ApiKeyContext = createContext<ApiKeyContextType | undefined>(undefined);

// Create a provider component to wrap your entire application
interface ApiKeyProviderProps {
  children: ReactNode;
}

export const ApiKeyProvider: React.FC<ApiKeyProviderProps> = ({ children }) => {
  const [apiKey, setApiKey] = useState<any>(
    sessionStorage.getItem("userCredentials") ? JSON.parse(sessionStorage.getItem("userCredentials") as string) : ""
  );
  // You can add other state or functions related to API key management here

const api_key = apiKey.api_key;
  return (
    <ApiKeyContext.Provider value={{ apiKey, setApiKey, api_key }}>
      {children}
    </ApiKeyContext.Provider>
  );
};

// Create a custom hook to use the API key context
export const useApiKey = (): ApiKeyContextType => {
  const context = useContext(ApiKeyContext);

  if (!context) {
    throw new Error('useApiKey must be used within an ApiKeyProvider');
  }

  return context;
};
