// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FeaturedVerb_Para{
    color : #A0A8C4; 
    font-size: 13px;
    font-family: "Montserrat", sans-serif;
}

#Main_Container{
    display: flex;
    justify-content: flex-start;
    width: 100%;
}

.Main_Container_Dynamic_Button{
    margin: 0.8%;
    
    color: #BB9CFF;
    border: none;
    min-width: 70px;
    padding: 4px 6px;
    border-radius: 0; /* Reset default border-radius */
  
    /* Rounded left and right sides */
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
  
    /* Add your other styles here */
    background: #31395A;
    font-size: 12px;
}`, "",{"version":3,"sources":["webpack://./src/styles/FeaturedVerb.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,eAAe;IACf,qCAAqC;AACzC;;AAEA;IACI,aAAa;IACb,2BAA2B;IAC3B,WAAW;AACf;;AAEA;IACI,YAAY;;IAEZ,cAAc;IACd,YAAY;IACZ,eAAe;IACf,gBAAgB;IAChB,gBAAgB,EAAE,gCAAgC;;IAElD,iCAAiC;IACjC,4BAA4B;IAC5B,+BAA+B;IAC/B,6BAA6B;IAC7B,gCAAgC;;IAEhC,+BAA+B;IAC/B,mBAAmB;IACnB,eAAe;AACnB","sourcesContent":[".FeaturedVerb_Para{\n    color : #A0A8C4; \n    font-size: 13px;\n    font-family: \"Montserrat\", sans-serif;\n}\n\n#Main_Container{\n    display: flex;\n    justify-content: flex-start;\n    width: 100%;\n}\n\n.Main_Container_Dynamic_Button{\n    margin: 0.8%;\n    \n    color: #BB9CFF;\n    border: none;\n    min-width: 70px;\n    padding: 4px 6px;\n    border-radius: 0; /* Reset default border-radius */\n  \n    /* Rounded left and right sides */\n    border-top-left-radius: 15px;\n    border-bottom-left-radius: 15px;\n    border-top-right-radius: 15px;\n    border-bottom-right-radius: 15px;\n  \n    /* Add your other styles here */\n    background: #31395A;\n    font-size: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
