// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Monaco_Editor_Outer_Wrapper {
    width: 100%;
    height: 100%;
}

.Monaco_Editor_Main_Container1 {
    width: 100%;
    height: 100%;
    box-sizing: border-box; /* Ensure borders are included in width/height */
}

/* Media queries for responsiveness */
@media (max-width: 1440px) {
    
    .Monaco_Editor_Main_Container1 {
        max-width: 100%;
        height: 100%; 
        margin: auto; /* Center the editor horizontally */
    }
}

@media (max-width: 1024px) {
    .Monaco_Editor_Main_Container1 {
        max-width: 100%; /* Adjust as needed */
        height: 100%; /* Adjust height for medium-sized screens */
        margin: auto; /* Center the editor horizontally */
    }
}

@media (max-width: 768px) {
    .Monaco_Editor_Main_Container1 {
        max-width: 100%; /* Adjust as needed */
        height: 100; /* Adjust height for smaller screens */
        margin: auto; /* Center the editor horizontally */
    }
}

@media (max-width: 480px) {
    .Monaco_Editor_Main_Container1 {
        max-width: 100%; /* Adjust as needed */
        height: 100; /* Adjust height for even smaller screens */
        margin: auto; /* Center the editor horizontally */
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/MonacoEditor.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,sBAAsB,EAAE,gDAAgD;AAC5E;;AAEA,qCAAqC;AACrC;;IAEI;QACI,eAAe;QACf,YAAY;QACZ,YAAY,EAAE,mCAAmC;IACrD;AACJ;;AAEA;IACI;QACI,eAAe,EAAE,qBAAqB;QACtC,YAAY,EAAE,2CAA2C;QACzD,YAAY,EAAE,mCAAmC;IACrD;AACJ;;AAEA;IACI;QACI,eAAe,EAAE,qBAAqB;QACtC,WAAW,EAAE,sCAAsC;QACnD,YAAY,EAAE,mCAAmC;IACrD;AACJ;;AAEA;IACI;QACI,eAAe,EAAE,qBAAqB;QACtC,WAAW,EAAE,2CAA2C;QACxD,YAAY,EAAE,mCAAmC;IACrD;AACJ","sourcesContent":[".Monaco_Editor_Outer_Wrapper {\n    width: 100%;\n    height: 100%;\n}\n\n.Monaco_Editor_Main_Container1 {\n    width: 100%;\n    height: 100%;\n    box-sizing: border-box; /* Ensure borders are included in width/height */\n}\n\n/* Media queries for responsiveness */\n@media (max-width: 1440px) {\n    \n    .Monaco_Editor_Main_Container1 {\n        max-width: 100%;\n        height: 100%; \n        margin: auto; /* Center the editor horizontally */\n    }\n}\n\n@media (max-width: 1024px) {\n    .Monaco_Editor_Main_Container1 {\n        max-width: 100%; /* Adjust as needed */\n        height: 100%; /* Adjust height for medium-sized screens */\n        margin: auto; /* Center the editor horizontally */\n    }\n}\n\n@media (max-width: 768px) {\n    .Monaco_Editor_Main_Container1 {\n        max-width: 100%; /* Adjust as needed */\n        height: 100; /* Adjust height for smaller screens */\n        margin: auto; /* Center the editor horizontally */\n    }\n}\n\n@media (max-width: 480px) {\n    .Monaco_Editor_Main_Container1 {\n        max-width: 100%; /* Adjust as needed */\n        height: 100; /* Adjust height for even smaller screens */\n        margin: auto; /* Center the editor horizontally */\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
