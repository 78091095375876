import React, { ReactNode } from "react";

interface ProjectContextProps {
  projectId: string | null;
  setProjectId: (name: string | null) => void;
  projectName: string | null;
  setProjectName: ((name: string | null) => void | undefined) | undefined;
  showEditorData: string | null;
  setShowEditorData: ((name: string | null) => void | undefined) | undefined;
  createProject: boolean;
  setCreateProject: ((name: boolean) => void | undefined) | undefined;
}

const ProjectContext = React.createContext<ProjectContextProps | undefined>(
  undefined
);

export const ProjectProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [projectId, setProjectId] = React.useState<string | null>(null);
  const [projectName, setProjectName] = React.useState<string | null>(null);
  const [createProject, setCreateProject] = React.useState<boolean>(false);
  const [showEditorData, setShowEditorData] = React.useState<string | null>(
    null
  );
  return (
    <ProjectContext.Provider
      value={{
        projectId,
        setProjectId,
        projectName,
        setProjectName,
        showEditorData,
        setShowEditorData,
        createProject,
        setCreateProject
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};

export const useProject = (): ProjectContextProps => {
  const context = React.useContext(ProjectContext);
  if (!context) {
    throw new Error("useProject must be used within an ProjectProvider");
  }
  return context;
};
