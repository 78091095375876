// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .Header_Main_Project_Button{
    font-family: 'Montserrat';
    font-weight: 700;
} */

/* .Header_Custom_Menu {
    overflow-y: scroll; 
    margin-right: 0px; 
}

.Header_Custom_Menu::-webkit-scrollbar {
    width: 10px; 
}

.Header_Custom_Menu::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 5px; 
}

.Header_Custom_Menu:hover::-webkit-scrollbar-thumb {
    background-color: #2d3857; 
} */


#ProjectButton{
    font-family: 'Montserrat';
    font-weight: 600;
    color: #7D88AC; 
}`, "",{"version":3,"sources":["webpack://./src/styles/Header.css"],"names":[],"mappings":"AAAA;;;GAGG;;AAEH;;;;;;;;;;;;;;;;GAgBG;;;AAGH;IACI,yBAAyB;IACzB,gBAAgB;IAChB,cAAc;AAClB","sourcesContent":["/* .Header_Main_Project_Button{\n    font-family: 'Montserrat';\n    font-weight: 700;\n} */\n\n/* .Header_Custom_Menu {\n    overflow-y: scroll; \n    margin-right: 0px; \n}\n\n.Header_Custom_Menu::-webkit-scrollbar {\n    width: 10px; \n}\n\n.Header_Custom_Menu::-webkit-scrollbar-thumb {\n    background-color: transparent;\n    border-radius: 5px; \n}\n\n.Header_Custom_Menu:hover::-webkit-scrollbar-thumb {\n    background-color: #2d3857; \n} */\n\n\n#ProjectButton{\n    font-family: 'Montserrat';\n    font-weight: 600;\n    color: #7D88AC; \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
