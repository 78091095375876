import React from "react";
import "./App.css";
import Header from "./components/header";
import Main from "./components/Main";
import { ApiKeyProvider } from "./generic components/ApiKeyContext";
import { useProject } from "./generic components/ProjectContext";

function App() {
  const { projectId , projectName } = useProject();

  React.useEffect(() => {
    document.title = "CodeSimple";

    const updatetitle = () => {
      document.title = `CodeSimple | ${projectName}`;
    };

    if (projectId !== null) {
      updatetitle();
    }
  }, [projectId , projectName]);

  return (
    <>
      <ApiKeyProvider>
        <Header />
        <Main />
      </ApiKeyProvider>
    </>
  );
}

export default App;
