import React from "react";
import "../styles/FileExplorer.css";
import folderIcon from "../assets/folderIcon.svg";
import angleSmallDown from "../assets/angle-small-down.svg";
import fi from "../assets/fileIcon.svg"; // Import your CSS file for styling
import AngleSmallClosed from "../assets/angle-small-closed.svg";
import styled from "@emotion/styled";
import FileClosedIcon from "../assets/FileClosedIcon.svg";
import { useProject } from "../generic components/ProjectContext";
import { BASE_URL } from "../config/config";
import { useApiKey } from "../generic components/ApiKeyContext";


// =========================================================================================================
//  CSS for FileExplorer.tsx

interface StyledComponentProps {
  expanded?: boolean;
  depth?: number;
  selected?: boolean;
}

const Para = styled.p<StyledComponentProps>`
  color: ${(props) => (props.selected ? "white" : "#A0A8C4")};
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  font-weight: ${(props) => (props.selected ? "700" : "")};
  margin-left: 10px;
`;

const FolderBox = styled.div<StyledComponentProps>`
  background: ${(props) =>
    props.selected
      ? "linear-gradient(to right ,rgba(89, 99, 131, 40%) , rgba(89, 99, 131, 0%))"
      : "transparent"};

  max-height: ${(props) => (props.selected ? "50px" : "35px")};
  margin-top: ${(props) => (props.selected ? "1.3%" : "0px")};
`;



// ===========================================================================================


interface FileExplorerProps {
  folderData?: any | unknown;
  rootName: string;
  rootParent?: string;
  depth?: number; // Add a depth prop to track the indentation level
  onSelect?: (selectedPath: string) => void;
  selectedPath?: string;
  setSelectedPath?: (selectedPath: string) => void;
}


const FileExplorer: React.FC<FileExplorerProps> = ({
  folderData,
  rootParent,
  rootName,
  depth = 0,
  onSelect,
  selectedPath,
  setSelectedPath,
}) => {
  const [expanded, setExpanded] = React.useState(false);

const {api_key} = useApiKey();
const {setShowEditorData, projectId} = useProject();

const handleSeletedFile = async (e: any, path: string) => {
  console.log(path)
  console.log("projectID" , projectId)
  const substringPath = path.substring(1);
  console.log("substringPath", substringPath)

  try{
    const response = await fetch(`${BASE_URL}/project/${projectId}/${substringPath}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-API-Key": api_key,
      },
    })
    const data =  await response.text();
    setShowEditorData!(data);
  } 
  catch(error){
    throw new Error(`Error in FileExplorer.tsx while fetching file code, ${error}`)
  }
};

  const handleSelect = (path: string) => {
    if (setSelectedPath) {
      setSelectedPath(path);
    }
  };

  const traverseObject = (data: any, name: string, rootParent: string) => {
    if (Array.isArray(data)) {
      return data.map((item: any, index: number) => {
        if (typeof item === "object" && item !== null) {
          return (
            <div key={index} style={{ marginLeft: `${depth * 2}px` }}>
              <FileExplorer
                folderData={item}
                rootParent={rootParent}
                rootName={name}
                depth={depth + 1}
                onSelect={handleSelect}
                setSelectedPath={setSelectedPath}
                selectedPath={selectedPath}
              />
            </div>
          );
        } else {
          const path = `${rootParent}/${name}/${item}`;
          return (
            <div
              key={index}
              className="file-container"
              style={{
                marginLeft: `${depth * 2.5}px`,
              }}
            >
              <FolderBox
                className="files"
                onClick={(e) => {
                  handleSelect(path);
                  handleSeletedFile(e, path);
                }}
                selected={selectedPath === path}
              >
                <img src={selectedPath === path ? fi : FileClosedIcon} alt="" />
                <Para selected={selectedPath === path}>{item}</Para>
              </FolderBox>
            </div>
          );
        }
      });
    } else if (typeof data === "object" && data !== null) {
      return Object.entries(data).map(([key, value], index) => {
        const path = `${rootParent}/${name}/${key}`;
        return (
          <div
            data-testid="file-explorer"
            key={index}
            style={{
              marginLeft: `${depth * 2}px`,
              color: "white",
              border: "2px",
            }}
            className="folder-container"
          >
            <FolderBox
              onClick={() => {
                setExpanded(!expanded);
                handleSelect(path);
              }}
              className="folder-box"
              expanded={expanded}
              selected={selectedPath === path}
            >
              <img src={expanded ? angleSmallDown : AngleSmallClosed} alt="" />
              <img src={folderIcon} alt="" />
              <Para selected={selectedPath === path}>{key}</Para>
            </FolderBox>
            {expanded && (
              <FileExplorer
                folderData={value}
                rootParent={rootParent + "/" + name}
                rootName={key}
                depth={depth + 1}
                onSelect={handleSelect}
                setSelectedPath={setSelectedPath}
                selectedPath={selectedPath}
              />
            )}
          </div>
        );
      });
    } else {
      return (
        <div style={{ marginLeft: `${depth * 2}px`, color: "white" }}>
          {data}
        </div>
      );
    }
  };

  return (
    <React.Fragment>
      {traverseObject(folderData, rootName, rootParent || "")}
    </React.Fragment>
  );
};

export default FileExplorer;
