import React from "react";
import AIBot from "../../../assets/AIBot.svg";
import { Divider, Paper } from "@mui/material";
import CommandCollapse from "./CommandCollapse";
import SmallAngleDown from "../../../assets/angle-small-down.svg";
import { BASE_URL } from "../../../config/config";
import FeaturedVerbs from "./FeaturedVerbs";
import { useApiKey } from "../../../generic components/ApiKeyContext";
import "../../../styles/AICommand.css";
import MonacoEditor from "../monacoEditor/MonacoEditor";
import { useProject } from "../../../generic components/ProjectContext";

function AICommands() {
  const [open, setOpen] = React.useState(false);
  const [verb, setVerb] = React.useState([]);
  const [featuredVerbs, setFeaturedVerbs] = React.useState({
    label: "",
    verbs: [] as any,
  } as any);

  const handleCommandDown = (show: boolean) => {
    setOpen(show);
  };

  const { api_key } = useApiKey();
  const { projectId } = useProject();

  React.useEffect(() => {
    const fetchVerb = async () => {
      try {
        const response = await fetch(`${BASE_URL}/verb `, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "X-API-Key": api_key || "",
          },
        });
        if (!response.ok) {
          throw new Error(
            `Error Fetching the project (HTTP ${response.status})`
          );
        }
        const data = await response.json();
        console.log("fetch verbs data", data);
        setVerb(data);
      } catch (err) {
        console.log(`Error Fetching the verbs ${err}`);
      }
    };

    const fetchFeaturedVerbs = async () => {
      try {
        const response = await fetch(`${BASE_URL}/featured_verbs `, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "X-API-Key": api_key || "",
          },
        });
        if (!response.ok) {
          throw new Error(
            `Error Fetching the project (HTTP ${response.status})`
          );
        }
        const data = await response.json();
        console.log(" Fetch featured verbs data", data);
        setFeaturedVerbs(data);
      } catch (err) {
        console.log(`Error Fetching the verbs ${err}`);
      }
    };

    fetchFeaturedVerbs();
    fetchVerb();
  }, [api_key]);

  return (
    <>
      <div
        style={{
          height: "91vh",
          display: "flex",
          flexDirection: "column",
        }}
        data-testid="AICommands-comp"
        className="AICommands_Main_Container"
      >
        <Paper>
          <div
            style={{
              height: "14vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              background:
                "linear-gradient(to bottom, rgba(27, 31, 49, 1), rgba(34, 38, 60, 1))",
            }}
            className="AICommands_Main_Box1"
          >
            <div
              style={{
                margin: "20px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  height: "7vh",
                }}
                className="AICommands_Main_Box1_Content"
              >
                <img
                  src={AIBot}
                  alt="AIBot"
                  style={{ paddingLeft: "10px", height: "24px", width: "24px" }}
                />

                <p>| What would you like me do for you ?</p>
              </div>
              <Divider sx={{ background: "#A0A8C4" }} />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  height: "7vh",
                }}
              >
                {featuredVerbs && verb && (
                  <FeaturedVerbs
                    featuredVerb={featuredVerbs.verbs}
                    projectId={projectId}
                  />
                )}

                <div
                  onClick={() => handleCommandDown(!open)}
                  style={{ display: "flex", cursor: "pointer" }}
                >
                  <p style={{ color: "#A0A8C4", fontSize: "13px" }}>Commands</p>
                  <img
                    src={SmallAngleDown}
                    alt=""
                    style={{ paddingLeft: "5px" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Paper>

        <div
          style={{
            position: "relative",
            background:
              "linear-gradient(to bottom, rgba(19, 23, 37, 1), rgba(36, 40, 63, 1))",
            height: "90vh",
          }}
        >
          <div
            style={{
              zIndex: "10",
              position: "absolute",
              background:
                "linear-gradient(to top, rgba(36, 40, 63, 1), rgba(19, 23, 37, 1))",
            }}
          >
            {open && (
              <CommandCollapse
                open={open}
                verb={verb}
                projectId={projectId}
                handleCommandDown={handleCommandDown}
              />
            )}
          </div>
          <div
            className="MonacoEditor_Component"
            style={{ height: "77vh", width: "100%" }}
          >
            <MonacoEditor />
          </div>
        </div>
      </div>
    </>
  );
}

export default AICommands;
