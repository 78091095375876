import { Routes, Route, BrowserRouter } from "react-router-dom";
import React from "react";
// import Login from "./components/login/Login";
import App from "./App";
import ProtectedRoutes from "./generic components/ProtectedRoutes";
import LandingPageMain from "./components/landingPage/LandingPageMain";
import { ProjectProvider } from "./generic components/ProjectContext";

export default function Router() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          {/* <Route path="/login" element={<Login />} /> */}
          <Route
            path="/Fire-X"
            element={
              <ProtectedRoutes>
                <ProjectProvider>
                  <App />
                </ProjectProvider>
              </ProtectedRoutes>
            }
          />
          <Route path="/" element={<LandingPageMain />} />
          <Route path="*" element={<h1>404 Not Found</h1>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}
