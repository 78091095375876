// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Sidebar_Main_Container {
    height: 91vh;
    overflow-y: hidden; 
    border-top-left-radius: 10px;
    background: linear-gradient(to right, rgba(11, 15, 27, 1), rgba(24, 28, 43, 1));
    position: relative;
    padding-right: 10px; 

}

.Sidebar_Main_Container {
    overflow-y: scroll; 
    margin-right: 0px; 
}

.Sidebar_Main_Container::-webkit-scrollbar {
    width: 10px; 
}


.Sidebar_Main_Container::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 5px; 
}
.Sidebar_Main_Container:hover::-webkit-scrollbar-thumb {
    background-color: #2d3857; 
}

.Explorer_Input {
    margin: 2%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Input_Box {
    width: 80%;
    background: transparent;
    border: none;
    margin: 2%;
    outline: none;
    color: #A0A8C4;
    opacity: 70%;
}

.Explorer_Input > img {
    margin: 2%;
}
`, "",{"version":3,"sources":["webpack://./src/styles/Sidebar.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,kBAAkB;IAClB,4BAA4B;IAC5B,+EAA+E;IAC/E,kBAAkB;IAClB,mBAAmB;;AAEvB;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,WAAW;AACf;;;AAGA;IACI,6BAA6B;IAC7B,kBAAkB;AACtB;AACA;IACI,yBAAyB;AAC7B;;AAEA;IACI,UAAU;IACV,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,UAAU;IACV,uBAAuB;IACvB,YAAY;IACZ,UAAU;IACV,aAAa;IACb,cAAc;IACd,YAAY;AAChB;;AAEA;IACI,UAAU;AACd","sourcesContent":[".Sidebar_Main_Container {\n    height: 91vh;\n    overflow-y: hidden; \n    border-top-left-radius: 10px;\n    background: linear-gradient(to right, rgba(11, 15, 27, 1), rgba(24, 28, 43, 1));\n    position: relative;\n    padding-right: 10px; \n\n}\n\n.Sidebar_Main_Container {\n    overflow-y: scroll; \n    margin-right: 0px; \n}\n\n.Sidebar_Main_Container::-webkit-scrollbar {\n    width: 10px; \n}\n\n\n.Sidebar_Main_Container::-webkit-scrollbar-thumb {\n    background-color: transparent;\n    border-radius: 5px; \n}\n.Sidebar_Main_Container:hover::-webkit-scrollbar-thumb {\n    background-color: #2d3857; \n}\n\n.Explorer_Input {\n    margin: 2%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.Input_Box {\n    width: 80%;\n    background: transparent;\n    border: none;\n    margin: 2%;\n    outline: none;\n    color: #A0A8C4;\n    opacity: 70%;\n}\n\n.Explorer_Input > img {\n    margin: 2%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
