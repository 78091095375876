// -----------------------------------------------------------------------------------------------------------------------------------------------

import { useState, useEffect, useRef } from "react";
import AlertDialogSlide from "../../DialogBox";
import React from "react";
import "../../../styles/FeaturedVerb.css";

interface FeaturedVerb {
  name: string;
  display_name: string;
}

interface FeaturedVerbsProps {
  label?: string;
  featuredVerb: FeaturedVerb[];
  maxContainerWidth?: number;
  projectId?: string | null;
}

function FeaturedVerbs(props: FeaturedVerbsProps) {
  const [openDialog, setOpenDialog] = useState(false);
  const [displayName, setDisplayName] = useState("");
  const [value, setValue] = useState("");
  const containerRef = useRef<HTMLDivElement>(null);

  const verbList = React.useMemo(() => {
    const list = [
      "test_drive",
      "meta_make",
      "modify_pipeline",
      "readme_pipeline",
      "add_method",
      "requirements_pipeline",
      "make_and_commit",
    ];
    if (props.projectId === null)
      return props.featuredVerb?.filter((item: any) =>
        list.includes(item.name) ? null : item
      );
    else return props.featuredVerb;
  }, [props.projectId, props.featuredVerb]);

  useEffect(() => {
    const calculateButtonWidth = () => {
      if (props.featuredVerb.length > 0) {
        // const container = document.getElementById("Main_Container");
        const container = containerRef.current;

        const createButton = () => {
          if (container) {
            container.innerHTML = "";

            const containerWidth = container.clientWidth;

            let totalButtonsWidth = 0;

            verbList.forEach((verb, index) => {
              const button = document.createElement("button");
              button.textContent = verb.display_name;

              container.appendChild(button);

              const buttonWidth = button.clientWidth;

              // Calculating the total width of buttons
              totalButtonsWidth += buttonWidth;

              button.className = "Main_Container_Dynamic_Button";
              button.onclick = () => handleClick(verb);

              // Check if total buttons width exceeds container width
              if (totalButtonsWidth > containerWidth) {
                // Hide button if it exceeds container width
                button.style.display = "none";
              }
            });
          }
        };
        createButton();

        // Re-adjust buttons on window resize
      }
    };
    window.addEventListener("resize", calculateButtonWidth);

    calculateButtonWidth();
    return () => {
      window.removeEventListener("resize", calculateButtonWidth);
    };
  }, [props.featuredVerb, verbList]);

  const handleClick = (verb: any) => {
    setValue(verb.name);
    setOpenDialog(true);
    setDisplayName(verb.display_name);
  };

  const callFromDialog = (boxOpen: boolean) => {
    setOpenDialog(boxOpen);
  };

  return (
    <>
      <p className="FeaturedVerb_Para">Featured&nbsp;Commands:&nbsp;</p>
      <div id="Main_Container" ref={containerRef}></div>
      {openDialog && (
        <AlertDialogSlide
          value={value}
          displayName={displayName}
          boxOpen={openDialog}
          callfromDialog={callFromDialog}
        />
      )}
    </>
  );
}

export default FeaturedVerbs;

// -----------------------------------------------------------------------------------------------------------------------------------------------
