import React from "react";
import { Collapse, Tooltip } from "@mui/material";
import Grid from "@mui/material/Grid";
import AlertDialogSlide from "../../DialogBox";
import "../../../styles/CommandCollapse.css";
import styled from "@emotion/styled";

export interface CommandCollapseProps {
  open?: boolean;
  verb?: any;
  projectId?: string | null;
  handleCommandDown?: (show: boolean) => void;
}

const TypographyStyle = styled.p`
  color: #bb9cff;
  overflow: hidden;
  cursor: pointer;
  margin: 3%;
  font-family: Montserrat;
  font-weight: 400;
  font-size: 13px;
`;

export default function CommandCollapse(props: CommandCollapseProps) {
  const [open, setOpen] = React.useState(props.open);
  const [value, setValue] = React.useState("");
  const [boxOpen, setBoxOpen] = React.useState(false);
  const [displayName, setDisplayName] = React.useState("");

  const handleClick = (itemName: string, itemDisplay: string) => {
    setBoxOpen(true);
    setOpen(false);
    setValue(itemName);
    setDisplayName(itemDisplay);
  };

  const callfromDialog = (boxOpen: boolean) => {
    setBoxOpen(boxOpen);
    props.handleCommandDown!(false);
  };

  const verbList = React.useMemo(() => {
    const list = [
      "test_drive",
      "meta_make",
      "modify_pipeline",
      "readme_pipeline",
      "add_method",
      "requirements_pipeline",
      "make_and_commit",
    ];
    if (props.projectId === null)
      return props.verb?.filter((item: any) =>
        list.includes(item.name) ? null : item
      );
    else return props.verb;
  }, [props.projectId, props.verb]);

  return (
    <div style={{}}>
      <Collapse in={open} sx={{}}>
        <Grid container spacing={2}>
          {verbList?.map((item: any, index: number) => (
            <Grid key={index} item xs={3}>
              <Tooltip title={item.name}>
                <TypographyStyle
                  className="CommandCollapse_List"
                  onClick={() => handleClick(item.name, item.display_name)}
                >
                  {item.display_name}
                </TypographyStyle>
              </Tooltip>
            </Grid>
          ))}
        </Grid>
      </Collapse>
      {boxOpen && (
        <AlertDialogSlide
          boxOpen={boxOpen}
          callfromDialog={callfromDialog}
          value={value}
          displayName={displayName}
        />
      )}
    </div>
  );
}
