import React from "react";
import "../../styles/Login.css";
import { BASE_URL } from "../../config/config";
import { useNavigate } from "react-router-dom";
import DialogGeneric from "../../generic components/DialogGeneric";

interface ProjectConfigItem {
  description: string;
  label: string;
  required: boolean;
  type: string;
}

interface ProjectConfig {
  [key: string]: ProjectConfigItem;
}

interface LoginProps {
  handleLoginClick? : (show : boolean) => void
}

function Login(props : LoginProps) {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);
  const [validationErrors, setValidationErrors] = React.useState<{
    [key: string]: string;
  }>({});
  const [formData, setFormData] = React.useState({});

  const handleClose = () => {
    setOpen(false);
    props.handleLoginClick!(false);
    navigate('/'); 
  };

  const displayName: string = "Login";
  const loginData: ProjectConfig = {
    login: {
      description: "User Name for login",
      label: "User Name",
      required: true,
      type: "string",
    },
    password: {
      description: "Password for login",
      label: "Password",
      required: true,
      type: "string",
    },
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    

    // Validate required fields
    const errors: { [key: string]: string } = {};
    Object.keys(loginData).forEach((key: string) => {
      const property: ProjectConfigItem = loginData[key];
      if (property.required && !formData[key as keyof typeof formData]) {
        errors[key] = `${property.label} is required.`;
      }
    });

    // Update state with validation errors
    setValidationErrors(errors);

    // If there are validation errors, stop submission
    if (Object.keys(errors).length > 0) {
      return;
    }

    try {
      const response = await fetch(`${BASE_URL}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      if (!response.ok) {
        throw new Error("Login failed");
      }
      setOpen(false);
      props.handleLoginClick!(false);
      e.target.reset();
      // Assuming your API returns a user object with username and token
      const apiResponse = await response.json();
      sessionStorage.setItem("userCredentials", JSON.stringify(apiResponse));
      console.log("success");
      navigate("/Fire-X");
    } catch (error) {
      console.log(`Error creating User ${error}`);
    }
  };

  const handleUpdate = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setValidationErrors({ ...validationErrors, [e.target.name]: "" }); // Clear error message on input change
  };

  return (
    <div data-testid="login-id">
      <DialogGeneric
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        handleUpdate={handleUpdate}
        loading={false}
        errorPost={false}
        open={open}
        displayName={displayName}
        fetchVerb={loginData}
        // value={props.value}
        // passToDialog={props.callfromDialog}
        validationErrors={validationErrors}
      />
    </div>

  );
}
export default Login;
